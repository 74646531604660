import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { SUBMIT_WITHDRAW_REWARD } from '@store/contractsInfo/actionTypes';
import { State, SubmitWithdrawRewardValue } from '@types';
import { toast } from 'react-toastify';
import { submitWithdrawReward } from '@api/deposits/submitWithdrawReward';
import { getAvailableValue, getBalance, getContractsInfo } from '@store/contractsInfo/actions';
import { error, request, success } from '@store/ui/actions';

function* submitReward({ payload, type }: SubmitWithdrawRewardValue) {
  try {
    yield put(request(type));
    const token = payload;
    const address = yield select((state: State) => state.wallet.address);

    yield call(submitWithdrawReward, address, token);
    yield put(success(type));

    yield put(getContractsInfo(token));
    yield put(getBalance());
    yield put(getAvailableValue(token));
  } catch (e) {
    toast.error(e.message);
    yield put(error(type));
  }
}

export function* submitWithdrawRewardSaga() {
  yield takeLatest(
    SUBMIT_WITHDRAW_REWARD,
    submitReward,
  );
}
