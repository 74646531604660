import { fromWeiUtil, getContract } from '@utils';
import { btcPeakAbi, ethPeakAbi } from '@assets/abi';
import { Tokens } from '@types';

export const getReward = async (token: Tokens, isFromWei: boolean = true) => {
  try {
    const abi = token === 'eth' ? ethPeakAbi : btcPeakAbi;
    const url = token === 'eth' ? process.env.REACT_APP_ETH_PEAK_PEAKDEFI_URL : process.env.REACT_APP_BTC_PEAK_PEAKDEFI_URL;

    const contract = await getContract(abi, url);

    const reward = await contract
      .methods
      .getRewardForDuration()
      .call();

    if (isFromWei) {
      return await fromWeiUtil(reward, 'peak');
    }

    return reward;
  } catch {
    throw new Error('Error getting reward');
  }
};
