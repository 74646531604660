import { fromWeiUtil, getContract } from '@utils';
import { btcPeakAbi, ethPeakAbi } from '@assets/abi';
import { Tokens } from '@types';

export const getEarned = async (address: string, token: Tokens) => {
  try {
    const abi = token === 'eth' ? ethPeakAbi : btcPeakAbi;
    const url = token === 'eth' ? process.env.REACT_APP_ETH_PEAK_PEAKDEFI_URL : process.env.REACT_APP_BTC_PEAK_PEAKDEFI_URL;

    const contract = await getContract(abi, url);

    const earned = await contract
      .methods
      .earned(address)
      .call();

    return await fromWeiUtil(earned, 'peak');
  } catch {
    throw new Error('Error getting earnings');
  }
};
