import React, { useMemo } from 'react';
import cx from 'classnames';

import { WalletsType } from '@types';
import { Button } from '@components';
import WalletAddress from '../WalletAddress';
import Modal from '../Modal';
import styles from './styles.module.scss';

type ChangeAccountModalProps = {
  className?: string,
  isOpen: boolean,
  onChange: () => void,
  onClose: () => void,
  address: string,
  walletType: WalletsType,
  onWCDisconnect: () => void,
};

const ChangeAccountModal = ({
  className,
  isOpen,
  onClose,
  onChange,
  address,
  walletType,
  onWCDisconnect,
}: ChangeAccountModalProps) => {
  const isWalletConnect = useMemo(
    () => walletType === 'walletConnect',
    [walletType],
  );

  return (
    <Modal
      title="Account"
      className={cx(styles.changeAccountModal, className)}
      isOpen={isOpen}
      onClose={onClose}
      classNameContent={styles.content}
    >
      <WalletAddress address={address} walletType={walletType} />
      <div className={cx(
        styles.buttonContainer,
        isWalletConnect && styles.buttonContainerWC,
      )}
      >
        {isWalletConnect && (
          <Button
            className={styles.button}
            onClick={onWCDisconnect}
            theme="gray"
            value="Disconnect"
          />
        )}
        <Button className={styles.button} onClick={onChange} theme="blue" value="Change" />
      </div>
    </Modal>
  );
};

ChangeAccountModal.defaultProps = {
  className: '',
};

export default ChangeAccountModal;
