import { fromWeiUtil, getContract } from '@utils';
import { btcPeakAbi, ethPeakAbi } from '@assets/abi';
import { Tokens } from '@types';

export const getBalance = async (address: string, token: Tokens, isFromWei: boolean = true) => {
  try {
    const abi = token === 'eth' ? ethPeakAbi : btcPeakAbi;
    const url = token === 'eth' ? process.env.REACT_APP_ETH_PEAK_PEAKDEFI_URL : process.env.REACT_APP_BTC_PEAK_PEAKDEFI_URL;

    const contract = await getContract(abi, url);

    const balance = await contract
      .methods
      .balanceOf(address)
      .call();

    if (isFromWei) {
      return await fromWeiUtil(balance, 'lp');
    }

    return balance;
  } catch {
    throw new Error('Error getting balance');
  }
};
