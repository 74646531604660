import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_AVAILABLE } from '@store/contractsInfo/actionTypes';
import { GetAvailableValue } from '@types';
import { getAvailable } from '@api/deposits/getAvailable';
import { getAvailableValueSuccess } from '@store/contractsInfo/actions';
import { toast } from 'react-toastify';

function* available({ payload: token }: GetAvailableValue) {
  try {
    const availableValue = yield call(getAvailable, token);
    yield put(getAvailableValueSuccess(token, availableValue));
  } catch (error) {
    toast.error(error.message);
  }
}

export function* getAvailableValueSaga() {
  yield takeEvery(
    GET_AVAILABLE,
    available,
  );
}
