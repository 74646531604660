import { WalletType } from '@constants';
import { useMetamask, useWalletconnect } from '@hooks';
import Web3 from 'web3';
import { store } from '../index';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let providerUnselected: any = null;

const web3 = async () => {
  const { walletType } = store.getState().wallet;

  const providerGetters = {
    [WalletType.METAMASK]: useMetamask,
    [WalletType.WALLETCONNECT]: useWalletconnect,
    [WalletType.UNSELECTED]: process.env.REACT_APP_INFURA_URL as string,
  };

  if (walletType === WalletType.UNSELECTED) {
    if (!providerUnselected) {
      providerUnselected = new Web3(providerGetters[walletType]);
    }

    return providerUnselected;
  }

  return providerGetters[walletType]();
};

export default web3;
