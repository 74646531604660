import React, { ReactNode } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type MaskProps = {
  className?: string,
  children: ReactNode,
};

const Mask = ({
  className,
  children,
}: MaskProps) => (
  <div className={cx(styles.mask, className)}>
    {children}
  </div>
);

Mask.defaultProps = {
  className: '',
};

export default Mask;
