import React, { useMemo } from 'react';
import cx from 'classnames';
import { IconMetamask, IconWallet } from '@assets/icons';
import { WalletsType } from '@types';
import styles from './styles.module.scss';

type WalletType = {
  icon: string,
  title: string,
};

const wallets: Record<WalletsType, WalletType> = {
  metamask: {
    icon: IconMetamask,
    title: 'Metamask',
  },
  walletConnect: {
    icon: IconWallet,
    title: 'Wallet connect',
  },
};

type WalletAddressProps = {
  className?: string,
  walletType: WalletsType,
  address: string,
};

const WalletAddress = ({ className, walletType, address }: WalletAddressProps) => {
  const addressSplice = useMemo(() => {
    if (address) {
      return {
        partFirst: address.substr(0, address.length - 11),
        partSecond: address.substr(-11),
      };
    }

    return {
      partFirst: '',
      partSecond: '',
    };
  }, [address]);

  return (
    <div className={cx(styles.walletAddress, className)}>
      <img src={wallets[walletType].icon} alt={wallets[walletType].title} />
      <div className={styles.valueWrapper}>
        <span className={styles.valuePartFirst}>{addressSplice.partFirst}</span>
        <span className={styles.value}>{addressSplice.partSecond}</span>
      </div>
    </div>
  );
};

WalletAddress.defaultProps = {
  className: '',
};

export default WalletAddress;
