import React from 'react';
import cx from 'classnames';

import { FAQ } from '@types';
import styles from './styles.module.scss';
import Dropdown from './dropdown';

type FaqProps = {
  className?: string,
  title?: string,
  faqs: FAQ[],
};

const Faq = ({
  className,
  title,
  faqs,
}: FaqProps) => (
  <section className={cx(styles.faq, className)}>
    <h2 className={styles.title}>{title}</h2>
    <ul className={styles.list}>
      {faqs.map((faq) => (
        <li className={styles.item} key={faq.title}>
          <Dropdown title={faq.title} content={faq.content} />
        </li>
      ))}
    </ul>
  </section>
);

Faq.defaultProps = {
  className: '',
  title: 'FAQ',
};

export default Faq;
