import { ContractInfo, ContractsInfoActions, ContractsInfoState } from '@types';
import {
  GET_BALANCE_SUCCESS, GET_CONTRACTS_INFO_SUCCESS, GET_TIME_SUCCESS,
  CONTRACTS_RESET, GET_CONTRACT_APY_SUCCESS, GET_AVAILABLE_SUCCESS, GET_WEEK_INCOME_SUCCESS,
} from '@store/contractsInfo/actionTypes';

const getInitialState = (): ContractInfo => ({
  lp: '0',
  lpAvailable: '0',
  peak: '0',
  reward: '0',
  available: '0',
  weekIncome: '0',
  apy: '0',
});

const initialState: ContractsInfoState = {
  balance: {
    eth: '0',
    peak: '0',
  },
  eth: getInitialState(),
  wbtc: getInitialState(),
  time: '0',
};

export const contractsInfoReducer = (
  state: ContractsInfoState = initialState,
  action: ContractsInfoActions,
): ContractsInfoState => {
  switch (action.type) {
    case GET_CONTRACTS_INFO_SUCCESS: {
      const { token, payload } = action.payload;

      return {
        ...state,
        [token]: { ...state[token], ...payload },
      };
    }

    case GET_BALANCE_SUCCESS: {
      const { eth, peak } = action.payload;

      return {
        ...state,
        balance: { eth, peak },
      };
    }

    case GET_TIME_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        time: payload,
      };
    }

    case CONTRACTS_RESET: {
      return {
        ...initialState,
        time: state.time,
        eth: {
          ...initialState.eth,
          apy: state.eth.apy,
          available: state.eth.available,
        },
        wbtc: {
          ...initialState.wbtc,
          apy: state.wbtc.apy,
          available: state.wbtc.available,
        },
      };
    }

    case GET_CONTRACT_APY_SUCCESS: {
      const { token, apy } = action.payload;

      return {
        ...state,
        [token]: { ...state[token], apy },
      };
    }

    case GET_AVAILABLE_SUCCESS: {
      const { token, available } = action.payload;

      return {
        ...state,
        [token]: { ...state[token], available },
      };
    }

    case GET_WEEK_INCOME_SUCCESS: {
      const { token, weekIncome } = action.payload;

      return {
        ...state,
        [token]: { ...state[token], weekIncome },
      };
    }

    default:
      return { ...state };
  }
};
