import React, { ReactNode } from 'react';
import RModal from 'react-modal';
import cx from 'classnames';
import styles from './styles.module.scss';

type ModalProps = {
  children: ReactNode,
  isOpen: boolean,
  className?: string,
  classNameContent?: string,
  onClose: () => void,
  title: string,
  isError?: boolean,
  onBack?: () => void,
};

const Modal = ({
  className, children, isOpen, onClose,
  title, isError, onBack, classNameContent,
}: ModalProps) => (
  <RModal
    appElement={document.getElementById('root') || document.body}
    className={cx(styles.modal, className)}
    shouldCloseOnEsc
    shouldCloseOnOverlayClick
    isOpen={isOpen}
    onRequestClose={onClose}
    overlayClassName={styles.overlay}
  >
    <div className={styles.header}>
      {isError && (
        <button onClick={onBack} type="button" className={cx(styles.icon, styles.iconBack)}>
          <i className="icon-angle-left" />
        </button>
      )}

      <h2
        className={cx(
          { [styles.title]: !isError },
          { [styles.red]: isError },
        )}
      >
        {isError ? 'Error!' : title}
      </h2>

      <button onClick={onClose} type="button" className={cx(styles.icon, styles.iconClose)}>
        <i className="icon-close" />
      </button>
    </div>

    <div className={cx(styles.content, classNameContent)}>
      {children}
    </div>
  </RModal>
);

Modal.defaultProps = {
  className: '',
  classNameContent: '',
  isError: false,
  onBack: () => {},
};

export default Modal;
