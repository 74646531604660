export const GET_CONTRACTS_INFO: 'GET_CONTRACTS_INFO' = 'GET_CONTRACTS_INFO';
export const GET_CONTRACTS_INFO_SUCCESS: 'GET_CONTRACTS_INFO_SUCCESS' = 'GET_CONTRACTS_INFO_SUCCESS';

export const GET_APPROVE: 'GET_APPROVE' = 'GET_APPROVE';
export const GET_APPROVE_SUCCESS: 'GET_APPROVE_SUCCESS' = 'GET_APPROVE_SUCCESS';

export const SUBMIT_STAKE: 'SUBMIT_STAKE' = 'SUBMIT_STAKE';
export const SUBMIT_WITHDRAW: 'SUBMIT_WITHDRAW' = 'SUBMIT_WITHDRAW';

export const GET_BALANCE: 'GET_BALANCE' = 'GET_BALANCE';
export const GET_BALANCE_SUCCESS: 'GET_BALANCE_SUCCESS' = 'GET_BALANCE_SUCCESS';

export const GET_TIME: 'GET_TIME' = 'GET_TIME';
export const GET_TIME_SUCCESS: 'GET_TIME_SUCCESS' = 'GET_TIME_SUCCESS';
export const SUBMIT_WITHDRAW_REWARD: 'SUBMIT_WITHDRAW_REWARD' = 'SUBMIT_WITHDRAW_REWARD';

export const CONTRACTS_RESET: 'CONTRACTS_RESET' = 'CONTRACTS_RESET';

export const GET_CONTRACT_APY: 'GET_CONTRACT_APY' = 'GET_CONTRACT_APY';
export const GET_CONTRACT_APY_SUCCESS: 'GET_CONTRACT_APY_SUCCESS' = 'GET_CONTRACT_APY_SUCCESS';

export const GET_AVAILABLE: 'GET_AVAILABLE' = 'GET_AVAILABLE';
export const GET_AVAILABLE_SUCCESS: 'GET_AVAILABLE_SUCCESS' = 'GET_AVAILABLE_SUCCESS';

export const GET_WEEK_INCOME: 'GET_WEEK_INCOME' = 'GET_WEEK_INCOME';
export const GET_WEEK_INCOME_SUCCESS: 'GET_WEEK_INCOME_SUCCESS' = 'GET_WEEK_INCOME_SUCCESS';

export default {
  GET_CONTRACTS_INFO,
  GET_CONTRACTS_INFO_SUCCESS,

  GET_APPROVE,
  GET_APPROVE_SUCCESS,

  SUBMIT_STAKE,
  SUBMIT_WITHDRAW,

  GET_BALANCE,
  GET_BALANCE_SUCCESS,
  CONTRACTS_RESET,

  GET_TIME,
  GET_TIME_SUCCESS,

  SUBMIT_WITHDRAW_REWARD,

  GET_CONTRACT_APY,
  GET_CONTRACT_APY_SUCCESS,

  GET_WEEK_INCOME,
  GET_WEEK_INCOME_SUCCESS,
};
