import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import type { match as Match } from 'react-router-dom';
import { Dispatch as DispatchRedux } from 'redux';
import {
  BalanceBlock, Button, ManageModal, Mask,
} from '@components';
import {
  GetApproveValuePayload, GetApproveValueSuccessPayload, State, Tokens,
} from '@types';
import { connect, ConnectedProps } from 'react-redux';
import {
  getApproveValue, getApproveValueSuccess, getAvailableValue, getWeekIncome, submitStakeValue,
  submitWithdrawValue,
} from '@store/contractsInfo/actions';
import { WalletType } from '@constants';
import cx from 'classnames';
import { getApproveCount } from '@api/deposits/getApproveCount';
import { success } from '@store/ui/actions';
import styles from './styles.module.scss';
import { ContextPopups } from '../../containers/Layout';

const mapState = (state: State) => ({
  isConnected: state.wallet.isConnected,
  address: state.wallet.address,
  walletType: state.wallet.walletType,
  contractsInfo: state.contractsInfo,
  approveStatus: state.ui.GET_APPROVE,
  submitStakeStatus: state.ui.SUBMIT_STAKE,
});

const mapDispatch = (dispatch: DispatchRedux) => ({
  onApprove: (
    payload: GetApproveValuePayload,
  ) => dispatch(getApproveValue(payload)),

  onApproveBreak: (
    payload: GetApproveValueSuccessPayload,
  ) => dispatch(getApproveValueSuccess(payload)),

  onSubmitStake: (
    token: Tokens,
    value: string,
  ) => {
    dispatch(submitStakeValue(token, value));
  },

  onSubmitWithdraw: (
    token: Tokens,
    value: string,
  ) => {
    dispatch(submitWithdrawValue(token, value));
  },

  onGetAvailable: (token: Tokens) => dispatch(getAvailableValue(token)),
  onGetWeekIncome: (token: Tokens) => dispatch(getWeekIncome(token)),
  onSuccess: (token: Tokens) => dispatch(success<GetApproveValueSuccessPayload>('GET_APPROVE', { token })),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ManageProps = PropsFromRedux & {
  match: Match<{ token: 'eth' | 'wbtc' }>
};

const Manage = ({
  match, contractsInfo, onApprove, onSubmitStake, onSubmitWithdraw,
  walletType, approveStatus, isConnected,
  address, submitStakeStatus, onGetAvailable, onGetWeekIncome, onSuccess,
}: ManageProps) => {
  const { token } = match.params;
  const { setIsOpenSelectWallet } = useContext(ContextPopups);

  const [modalIsOpen, setModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<'deposit' | 'withdraw'>('deposit');
  const [approveCount, setApproveCount] = useState('');
  const [amount, setAmount] = useState('');
  const [isApproveButtonDisabledWC, setIsApproveButtonDisabledWC] = useState(false);
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const timerId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    onGetAvailable(token);
  }, [token]);

  useEffect(() => {
    if (address) {
      onGetWeekIncome(token);
    }
  }, [address]);

  useEffect(() => () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }

    if (timerId.current) {
      clearTimeout(timerId.current);
    }
  }, []);

  useEffect(
    () => {
      if (address) {
        getApproveCount(address, token).then((value) => {
          setApproveCount(value);
        });
      }
    },
    [approveStatus, walletType, address, submitStakeStatus],
  );

  const isApproveButtonDisabledMM = useMemo(
    () => {
      if (walletType !== WalletType.WALLETCONNECT) {
        return approveStatus === 'REQUEST';
      }

      return false;
    },
    [approveStatus],
  );

  const onApproveClick = useCallback((value: string) => {
    onApprove({ token, value });
    if (walletType === WalletType.WALLETCONNECT) {
      setIsApproveButtonDisabledWC(true);
      timerId.current = setTimeout(() => {
        setIsApproveButtonDisabledWC(false);
      }, 30000);
    }
  }, [token, onApprove, walletType]);

  const closeHandler = useCallback(() => {
    setModalOpen(false);
    onSuccess(token);

    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
  }, [setModalOpen]);

  const modalDepositHandler = useCallback((value) => {
    onSubmitStake(token, value);
  }, []);

  const modalWithdrawHandler = useCallback((value) => {
    onSubmitWithdraw(token, value);
  }, []);

  const buttonDepositHandler = useCallback(async () => {
    setAmount(contractsInfo[token].lpAvailable);
    setModalOpen(true);
    setModalType('deposit');

    if (walletType === WalletType.WALLETCONNECT) {
      intervalId.current = setInterval(() => {
        getApproveCount(address, token).then((value) => {
          setApproveCount(value);
        });
      }, 1000);
    }
  }, [contractsInfo, approveStatus, walletType, address, submitStakeStatus, token]);

  const buttonWithdrawHandler = useCallback(() => {
    modalWithdrawHandler(contractsInfo[token].lp);
  }, [setModalOpen, contractsInfo]);

  const onOpenSelectWalletClick = useCallback(
    () => setIsOpenSelectWallet(true),
    [setIsOpenSelectWallet],
  );

  return (
    <div className={styles.manage}>
      <Mask className={styles.mask}>
        <h2 className={cx(styles.title, 'container')}>
          {`Deposit PEAK/${token.toUpperCase()} LP tokens and earn PEAK`}
        </h2>
      </Mask>
      <div className={cx(styles.container, 'container')}>
        <BalanceBlock
          token="lp"
          amount={contractsInfo[token].lp}
          className={styles.balanceBlock}
        />
        <BalanceBlock
          token="peak"
          amount={contractsInfo[token].peak}
          className={styles.balanceBlock}
          withShadow
        />
        <div className={styles.controlsGroup}>
          {isConnected ? (
            <Button
              value="Deposit"
              theme="green"
              onClick={buttonDepositHandler}
              disabled
            />
          ) : (
            <Button
              theme="blue"
              value="Unlock wallet"
              onClick={onOpenSelectWalletClick}
            />
          )}
          {(contractsInfo[token].lp !== '0' && isConnected) && (
            <Button
              value="Withdraw PEAK and LP"
              theme="green"
              onClick={buttonWithdrawHandler}
              className={styles.controlWithdraw}
            />
          )}
        </div>
        <ManageModal
          isOpen={modalIsOpen}
          onApprove={onApproveClick}
          onDeposit={modalDepositHandler}
          onWithdraw={modalWithdrawHandler}
          onClose={closeHandler}
          amount={amount}
          token={`PEAK/${token.toUpperCase()} LP`}
          type={modalType}
          approveCount={approveCount}
          isApproveButtonDisabled={isApproveButtonDisabledMM || isApproveButtonDisabledWC}
        />
      </div>
    </div>
  );
};

export default connector(Manage);
