import { fromWeiUtil, getContract } from '@utils';
import { tokenProxiAbi } from '@assets/abi';
import { Tokens } from '@types';

export const getAvailable = async (token: Tokens) => {
  try {
    const contract = await getContract(tokenProxiAbi, process.env.REACT_APP_TOKEN_PROXI_URL);
    const contractAddress = token === 'eth' ? process.env.REACT_APP_ETH_PEAK_PEAKDEFI_URL : process.env.REACT_APP_BTC_PEAK_PEAKDEFI_URL;

    const available = await contract
      .methods
      .balanceOf(contractAddress)
      .call();

    return await fromWeiUtil(available, 'peak');
  } catch (error) {
    throw new Error('Error getting available');
  }
};
