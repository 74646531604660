import React, {
  ChangeEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import cx from 'classnames';
import { Button, Input, PeakAvailable } from '@components';
import Modal from '../Modal';
import styles from './styles.module.scss';

type ManageModalProps = {
  className?: string,
  isOpen: boolean,
  onDeposit: (amount: string) => void,
  onApprove: (amount: string) => void,
  onWithdraw: (amount: string) => void,
  onClose: () => void,
  amount: string,
  token: string,
  type: 'deposit' | 'withdraw',
  approveCount: string,
  isApproveButtonDisabled: boolean,
};

const ManageModal = ({
  className, isOpen, onClose, type, onWithdraw,
  onDeposit, onApprove, amount, token, approveCount, isApproveButtonDisabled,
}: ManageModalProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isDisabledBtnWithdraw, setIsDisabledBtnWithdraw] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsDisabledBtnWithdraw(false);
    }
  }, [isOpen]);

  const isDisabledDepositValue = useMemo(() => {
    if (!inputValue || (+inputValue <= 0)) {
      return true;
    }

    if (+inputValue > +approveCount) {
      return true;
    }

    return +inputValue > +amount;
  }, [inputValue, approveCount, amount]);

  const isDisabledWithdraw = useMemo(
    () => !inputValue || +inputValue <= 0 || +inputValue > +amount,
    [amount, inputValue],
  );

  const colorPeakAvailable = useMemo<'green' | 'greyLight'>(() => {
    if (approveCount === '0' || +inputValue > +approveCount || !+inputValue || +inputValue > +amount) {
      return 'greyLight';
    }

    return 'green';
  }, [approveCount, inputValue]);

  const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const depositClickHandler = useCallback(() => {
    onDeposit(inputValue);
    onClose();
  }, [onDeposit, inputValue]);

  const approveClickHandler = useCallback(() => {
    if (!inputValue || (+inputValue <= 0)) {
      onApprove('0');
    } else {
      onApprove(inputValue);
    }
  }, [onApprove, inputValue]);

  const withdrawClickHandler = useCallback(() => {
    setIsDisabledBtnWithdraw(true);
    onWithdraw(inputValue);
    onClose();
  }, [onWithdraw, inputValue]);

  const handlerMaxClick = useCallback(() => {
    setInputValue(amount);
  }, [amount]);

  return (
    <Modal
      title={type === 'deposit' ? 'Deposit' : 'Withdraw'}
      className={cx(styles.manageModal, className)}
      isOpen={isOpen}
      onClose={onClose}
      classNameContent={cx(styles.content, styles[type])}
    >
      <PeakAvailable amount={amount} token={token} size="small" colorAmount="white" />
      <Input
        value={inputValue}
        onChange={inputChangeHandler}
        label={token}
        id="inputWithdraw"
        className={styles.input}
        onMaxClick={handlerMaxClick}
      />
      {type === 'deposit' && (
        <PeakAvailable
          className={styles.approved}
          amount=""
          token={token}
          size="micro"
          weightAmount="regular"
          postfix="approved"
          colorAmount={colorPeakAvailable}
          colorPostfix={colorPeakAvailable}
        />
      )}
      {type === 'withdraw' ? (
        <Button onClick={withdrawClickHandler} theme="gray" value="Withdraw" disabled={isDisabledBtnWithdraw || isDisabledWithdraw} />
      ) : (
        <div className={styles.depositGroup}>
          <Button
            onClick={approveClickHandler}
            theme="transparent"
            value="Approve"
            disabled={isApproveButtonDisabled}
          />
          <Button
            onClick={depositClickHandler}
            theme="green"
            value="Deposit"
            disabled={isDisabledDepositValue}
          />
        </div>
      )}
    </Modal>
  );
};

ManageModal.defaultProps = {
  className: '',
};

export default ManageModal;
