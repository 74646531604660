import { fork } from 'redux-saga/effects';
import { getContractsInfoSaga } from '@store/contractsInfo/sagas/getContractsInfo';
import { getBalanceSaga } from '@store/contractsInfo/sagas/getBalance';
import { submitWithdrawSaga } from '@store/contractsInfo/sagas/submitWithdraw';
import { submitStakeSaga } from '@store/contractsInfo/sagas/submitStake';
import { getApproveSaga } from '@store/contractsInfo/sagas/getApprove';
import { getTimeSaga } from '@store/contractsInfo/sagas/getTime';
import { submitWithdrawRewardSaga } from '@store/contractsInfo/sagas/submitWithdrawReward';
import { getContractApySaga } from '@store/contractsInfo/sagas/getContractApy';
import { getAvailableValueSaga } from '@store/contractsInfo/sagas/getAvailable';
import { getWeekIncomeSaga } from '@store/contractsInfo/sagas/getWeekIncome';

export const depositsEffects = [
  fork(getContractsInfoSaga),
  fork(getApproveSaga),
  fork(submitStakeSaga),
  fork(submitWithdrawSaga),
  fork(getBalanceSaga),
  fork(getTimeSaga),
  fork(submitWithdrawRewardSaga),
  fork(getContractApySaga),
  fork(getAvailableValueSaga),
  fork(getWeekIncomeSaga),
];
