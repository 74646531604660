import React, {
  ReactNode, useCallback, useEffect, useMemo, useState, Dispatch, SetStateAction, createContext,
} from 'react';
import cx from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch as DispatchRedux } from 'redux';
import { Header, ChangeAccountModal, SelectWalletModal } from '@components';
import { HeaderBalance, State, Tokens } from '@types';
import { connectWalletReset } from '@store/wallet/actions';
import { useMetamask, useWalletconnect } from '@hooks';
import {
  contractsReset, getBalance, getContractsInfo,
} from '@store/contractsInfo/actions';
import { toast } from 'react-toastify';
import { MetamaskRequestMethod } from '../../hooks/useMetamask';
import styles from './styles.module.scss';

const mapState = (state: State) => ({
  isConnected: state.wallet.isConnected,
  address: state.wallet.address,
  connectWalletStatus: state.ui.CONNECT_WALLET,
  walletType: state.wallet.walletType,
  balance: state.contractsInfo.balance,
});

const mapDispatch = (dispatch: DispatchRedux) => ({
  onConnectWalletReset: () => dispatch(connectWalletReset()),
  onContractsReset: () => dispatch(contractsReset()),
  onLoadBalance: () => dispatch(getBalance()),
  onLoadGetContractsInfo: (token: Tokens) => dispatch(getContractsInfo(token)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type LayoutProps = PropsFromRedux & {
  className?: string,
  children: ReactNode,
};

type ContextPopupsProps = {
  isOpenChangeAccount: boolean,
  setIsOpenChangeAccount: Dispatch<SetStateAction<boolean>>,
  isOpenSelectWallet: boolean,
  setIsOpenSelectWallet: Dispatch<SetStateAction<boolean>>,
};

export const ContextPopups = createContext<ContextPopupsProps>({
  isOpenChangeAccount: false,
  setIsOpenChangeAccount: () => {
  },
  isOpenSelectWallet: false,
  setIsOpenSelectWallet: () => {
  },
});

const Layout = ({
  className, children, isConnected, address, connectWalletStatus, walletType, onConnectWalletReset,
  balance, onContractsReset, onLoadBalance, onLoadGetContractsInfo,
}: LayoutProps) => {
  useEffect(() => {
    if (address) {
      onLoadBalance();
      onLoadGetContractsInfo('eth');
      onLoadGetContractsInfo('wbtc');
    }
  }, [address]);

  const balances = useMemo((): HeaderBalance[] => ([
    {
      value: balance.peak.toString(),
      currency: 'PEAK',
    },
    {
      value: balance.eth.toString(),
      currency: 'ETH',
    },
  ]), [balance]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (walletType === 'METAMASK' && isConnected) {
        const metamaskProvider = await useMetamask();
        if (metamaskProvider) {
          const addressMetamask = await metamaskProvider.currentProvider.request({
            method: MetamaskRequestMethod.eth_accounts,
          });

          if (!addressMetamask.length) {
            onConnectWalletReset();
            onContractsReset();
            toast.error('MetaMask was disconnected');
          }
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [walletType, isConnected]);

  const [isOpenChangeAccount, setIsOpenChangeAccount] = useState(false);
  const [isOpenSelectWallet, setIsOpenSelectWallet] = useState(false);

  const openSelectWalletClick = useCallback(
    () => setIsOpenSelectWallet(true),
    [setIsOpenSelectWallet],
  );

  const openChangeAccountClick = useCallback(
    () => setIsOpenChangeAccount(true),
    [setIsOpenSelectWallet],
  );

  const onChangeAccountModalClick = useCallback(async () => {
    onConnectWalletReset();
    onContractsReset();
    setIsOpenChangeAccount(false);
    setIsOpenSelectWallet(true);

    if (walletType === 'WALLETCONNECT') {
      const provider = await useWalletconnect();
      await provider!.currentProvider.connector.killSession();
    }
  }, [walletType]);

  const onCloseSelectWalletModal = useCallback(() => {
    setIsOpenSelectWallet(false);
  }, []);

  const onCloseChangeAccountModal = useCallback(() => {
    setIsOpenChangeAccount(false);
  }, []);

  useEffect(() => {
    if (connectWalletStatus === 'SUCCESS' && isOpenSelectWallet) {
      setIsOpenSelectWallet(false);
    }
  }, [connectWalletStatus]);

  const renderChangeAccountModal = useMemo(() => (
    <ChangeAccountModal
      address={address!}
      walletType={walletType === 'WALLETCONNECT' ? 'walletConnect' : 'metamask'}
      onChange={onChangeAccountModalClick}
      onClose={onCloseChangeAccountModal}
      isOpen={isOpenChangeAccount}
      onWCDisconnect={onChangeAccountModalClick}
    />
  ), [isOpenChangeAccount, address]);

  const renderSelectWalletModal = useMemo(() => (
    <SelectWalletModal
      isOpen={isOpenSelectWallet}
      metamaskIsLoading={connectWalletStatus === 'REQUEST' && walletType === 'METAMASK'}
      walletConnectIsLoading={connectWalletStatus === 'REQUEST' && walletType === 'WALLETCONNECT'}
      metamaskIsError={connectWalletStatus === 'ERROR' && walletType === 'METAMASK'}
      walletConnectIsError={connectWalletStatus === 'ERROR' && walletType === 'WALLETCONNECT'}
      onClose={onCloseSelectWalletModal}
    />
  ), [isOpenSelectWallet, connectWalletStatus]);

  return (
    <main className={cx(styles.layout, className)}>
      <Header
        isEnabled={isConnected}
        balances={balances}
        address={address}
        onAddressClick={openChangeAccountClick}
        onUnlockClick={openSelectWalletClick}
      />
      <ContextPopups.Provider value={{
        isOpenChangeAccount,
        setIsOpenChangeAccount,
        isOpenSelectWallet,
        setIsOpenSelectWallet,
      }}
      >
        {children}
      </ContextPopups.Provider>
      {renderChangeAccountModal}
      {renderSelectWalletModal}
    </main>
  );
};

Layout.defaultProps = {
  className: '',
};

export default connector(Layout);
