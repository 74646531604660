import { ConnectWalletSuccessPayload, ConnectWalletErrorPayload, ConnectWalletRequestPayload } from '@types';
import actionTypes from './actionTypes';

export const connectWallet = (payload: ConnectWalletRequestPayload) => ({
  type: actionTypes.CONNECT_WALLET_REQUEST,
  payload,
});

export const connectWalletSuccess = (payload: ConnectWalletSuccessPayload) => ({
  type: actionTypes.CONNECT_WALLET_SUCCESS,
  payload,
});

export const connectWalletError = (payload: ConnectWalletErrorPayload) => ({
  type: actionTypes.CONNECT_WALLET_ERROR,
  payload,
});

export const connectWalletReset = () => ({ type: actionTypes.CONNECT_WALLET_RESET });
