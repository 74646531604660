import { ethPeakAbi } from '@assets/abi';
import { getContract } from '@utils';

export const getTime = async (): Promise<string> => {
  try {
    const url = process.env.REACT_APP_BTC_PEAK_PEAKDEFI_URL;

    const contract = await getContract(ethPeakAbi, url);

    return contract
      .methods
      .periodFinish()
      .call();
  } catch (error) {
    throw new Error(error.message);
  }
};
