import {
  call, put, select, all, takeEvery,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { GetContractsInfo, State } from '@types';
import { getBalance } from '@api/deposits/getBalance';
import { getEarned } from '@api/deposits/getEarned';
import { getReward } from '@api/deposits/getReward';
import { getContractsInfoSuccess } from '@store/contractsInfo/actions';
import { GET_CONTRACTS_INFO } from '@store/contractsInfo/actionTypes';
import { getBalanceUniswap } from '@api/deposits/getBalanceUniswap';

function* getInfo({ token }: GetContractsInfo) {
  try {
    const address = yield select((state: State) => state.wallet.address);

    const [balance, earned, reward, lpAvailable]: string[] = yield all([
      call(getBalance, address, token),
      call(getEarned, address, token),
      call(getReward, token),
      call(getBalanceUniswap, address, token),
    ]);

    yield put(getContractsInfoSuccess(token, {
      lp: balance,
      peak: earned,
      reward: reward!,
      lpAvailable,
    }));
  } catch (error) {
    toast.error(error.message);
  }
}

export function* getContractsInfoSaga() {
  yield takeEvery(
    GET_CONTRACTS_INFO,
    getInfo,
  );
}
