import React, { memo } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type BalanceInfoProps = {
  className?: string,
  value: string,
  currency: string,
};

const BalancerInfo = ({
  className,
  value,
  currency,
}: BalanceInfoProps) => (
  <div className={cx(styles.balanceInfo, className)}>
    <p className={styles.value}>{value}</p>
    <p className={styles.currency}>{currency}</p>
  </div>
);

BalancerInfo.defaultProps = {
  className: '',
};

export default memo(BalancerInfo);
