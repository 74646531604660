export default [{
  inputs: [{
    internalType: 'address',
    name: '_rewardsDistribution',
    type: 'address',
  }, { internalType: 'address', name: '_rewardsToken', type: 'address' }, {
    internalType: 'address',
    name: '_stakingToken',
    type: 'address',
  }],
  stateMutability: 'nonpayable',
  type: 'constructor',
}, {
  anonymous: false,
  inputs: [{
    indexed: false, internalType: 'uint256', name: 'reward', type: 'uint256',
  }],
  name: 'RewardAdded',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'user', type: 'address',
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'reward',
    type: 'uint256',
  }],
  name: 'RewardPaid',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'user', type: 'address',
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256',
  }],
  name: 'Staked',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'user', type: 'address',
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256',
  }],
  name: 'Withdrawn',
  type: 'event',
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
  name: 'balanceOf',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
  name: 'earned',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [], name: 'exit', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [],
  name: 'getReward',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [],
  name: 'getRewardForDuration',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'lastTimeRewardApplicable',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'lastUpdateTime',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: 'reward', type: 'uint256' }],
  name: 'notifyRewardAmount',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [],
  name: 'periodFinish',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'rewardPerToken',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'rewardPerTokenStored',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'rewardRate',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '', type: 'address' }],
  name: 'rewards',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'rewardsDistribution',
  outputs: [{ internalType: 'address', name: '', type: 'address' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'rewardsDuration',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'rewardsToken',
  outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
  name: 'stake',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }, {
    internalType: 'uint256',
    name: 'deadline',
    type: 'uint256',
  }, { internalType: 'uint8', name: 'v', type: 'uint8' }, {
    internalType: 'bytes32',
    name: 'r',
    type: 'bytes32',
  }, { internalType: 'bytes32', name: 's', type: 'bytes32' }],
  name: 'stakeWithPermit',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [],
  name: 'stakingToken',
  outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'totalSupply',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '', type: 'address' }],
  name: 'userRewardPerTokenPaid',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
  name: 'withdraw',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}];
