import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { getBalanceEth } from '@api/deposits/getBalanceEth';
import { State } from '@types';
import { getBalancePeak } from '@api/deposits/getBalancePeak';
import { getBalanceSuccess } from '@store/contractsInfo/actions';
import { GET_BALANCE } from '@store/contractsInfo/actionTypes';

function* get() {
  try {
    const address = yield select((state: State) => state.wallet.address);
    const ethBalance = yield call(getBalanceEth, address);
    const peakBalance = yield call(getBalancePeak, address);

    yield put(getBalanceSuccess(ethBalance, peakBalance));
  } catch (error) {
    toast.error(error.message);
  }
}

export function* getBalanceSaga() {
  yield takeLatest(
    GET_BALANCE,
    get,
  );
}
