export const request = <P, T extends string = string>(defaultType: T, payload?: P) => ({
  type: `${defaultType}_REQUEST`,
  payload,
});

export const success = <P, T extends string = string>(defaultType: T, payload?: P) => ({
  type: `${defaultType}_SUCCESS`,
  payload,
});

export const error = <E, T extends string = string>(defaultType: T, err?: E) => ({
  type: `${defaultType}_ERROR`,
  payload: err,
});

export const reset = <T extends string = string>(defaultType: T) => ({
  type: `${defaultType}_RESET`,
});
