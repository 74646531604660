import { fromWeiUtil, getContract } from '@utils';
import { tokenProxiAbi } from '@assets/abi';

export const getAllPeaks = async (token: string, isFromWei: boolean = true) => {
  try {
    const urlPool = token === 'eth' ? process.env.REACT_APP_ETH_PEAK_UNISWAP_URL : process.env.REACT_APP_BTC_PEAK_UNISWAP_URL;
    const contract = await getContract(tokenProxiAbi, process.env.REACT_APP_TOKEN_PROXI_URL);

    const available = await contract
      .methods
      .balanceOf(urlPool)
      .call();

    if (isFromWei) {
      return await fromWeiUtil(available, 'peak');
    }

    return available;
  } catch (error) {
    throw new Error('Error getting available');
  }
};
