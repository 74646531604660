import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '@utils';
import configureStore from '@store/configureStore';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Layout } from '@containers';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import 'react-toastify/dist/ReactToastify.css';
import '@assets/styles/main.scss';

const config = configureStore();
export const { store } = config;
const { persistor } = config;
const root = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Layout>
          <Routes />
          <ToastContainer hideProgressBar />
        </Layout>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  root,
);

serviceWorker.unregister();
