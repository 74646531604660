import React, { ReactNode } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type StepsProps = {
  className?: string,
  values: ReactNode[],
};

const Steps = ({ className, values }: StepsProps) => (
  <ul className={cx(styles.steps, className)}>
    {values.map((item) => (
      <li key={Math.random()} className={styles.step}>
        {item}
      </li>
    ))}
  </ul>
);

Steps.defaultProps = {
  className: '',
};

export default Steps;
