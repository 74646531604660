import { TokensPool } from '@types';
import round from 'lodash.round';
import BigNumber from 'bignumber.js';
import web3 from './web3';

export const toWeiUtil = async (value: string, token: TokensPool): Promise<string> => {
  const provider = await web3();

  let result;

  if (token === 'lp') {
    result = provider.utils.toWei(value, 'ether');
  } else {
    result = +provider.utils.toWei(value, 'nano') / 10;
  }

  result = round(+result, 1);

  const BN = new BigNumber(result);

  return BN.toFixed();
};

export const fromWeiUtil = async (value: string, token: TokensPool) => {
  const provider = await web3();

  let result;

  if (token === 'lp') {
    result = provider.utils.fromWei(value, 'ether');
  } else {
    result = +provider.utils.fromWei(value, 'gwei') * 10;
    result = round(+result, 8);
  }

  const BN = new BigNumber(result);

  return BN.toFixed();
};

export const fromEthToWei = async (value: string): Promise<string> => {
  const provider = await web3();

  return provider.utils.toWei(value, 'ether');
};
