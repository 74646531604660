import React, { memo, MouseEvent, ReactNode } from 'react';
import cx from 'classnames';
import { ButtonTheme } from '@types';

import styles from './styles.module.scss';

type ButtonProps = {
  className?: string,
  theme: ButtonTheme,
  isFluid?: boolean,
  type?: 'button' | 'submit' | 'reset',
  value: string | ReactNode,
  onClick: (event: MouseEvent) => void,
  disabled?: boolean,
};

const Button = ({
  type,
  className,
  value,
  onClick,
  theme,
  isFluid,
  disabled,
}: ButtonProps) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    className={cx(
      styles.button,
      styles[theme],
      { [styles.fluid]: isFluid },
      className,
    )}
    onClick={onClick}
    disabled={disabled}
  >
    {value}
  </button>
);

Button.defaultProps = {
  className: '',
  isFluid: false,
  type: 'button',
  disabled: false,
};

export default memo(Button);
