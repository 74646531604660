import {
  takeLatest, call, put,
} from 'redux-saga/effects';
import { GET_TIME } from '@store/contractsInfo/actionTypes';
import { toast } from 'react-toastify';
import { getTime as getContractTime } from '@api/deposits/getTime';
import { getTimeSuccess } from '../actions';

function* getTime() {
  try {
    const time = yield call(getContractTime);
    yield put(getTimeSuccess((+time * 1000).toString()));
  } catch (error) {
    toast.error(error.message);
  }
}

export function* getTimeSaga() {
  yield takeLatest(
    GET_TIME,
    getTime,
  );
}
