import WalletConnectProvider from '@walletconnect/web3-provider';

const getWalletconnectProvider = () => {
  const chainId = +(process.env.REACT_APP_CHAIN_ID || 1);

  return new WalletConnectProvider({
    infuraId: process.env.REACT_APP_INFURA_ID,
    chainId,
  });
};

export default getWalletconnectProvider;
