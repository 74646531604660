import {
  call, select, put, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { getApprove } from '@api/deposits/getApprove';
import { GetApproveValue, GetApproveValueSuccessPayload, State } from '@types';
import { GET_APPROVE } from '@store/contractsInfo/actionTypes';
import { request, success, error } from '@store/ui/actions';

function* approve({ payload, type }: GetApproveValue) {
  yield put(request(type));
  const { token } = payload;

  try {
    const address = yield select((state: State) => state.wallet.address);

    yield call(getApprove, address, token);
    yield put(success<GetApproveValueSuccessPayload>(type, { token }));
  } catch (e) {
    toast.error(e.message);
    yield put(error<GetApproveValueSuccessPayload>(type, { token }));
  }
}

export function* getApproveSaga() {
  yield takeLatest(
    GET_APPROVE,
    approve,
  );
}
