import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import { GET_WEEK_INCOME } from '@store/contractsInfo/actionTypes';
import { toast } from 'react-toastify';
import { GetWeekIncome, State } from '@types';
import { getBalance } from '@api/deposits/getBalance';
import { getReward } from '@api/deposits/getReward';
import { getStakingRewardLP } from '@api/deposits/getStakingRewardLP';
import { fromWeiUtil } from '@utils';
import { getWeekIncomeSuccess } from '@store/contractsInfo/actions';

function* getWeekIncome({ payload: token }: GetWeekIncome) {
  try {
    const address = yield select((state: State) => state.wallet.address);

    const [userLP, reward, stakingRewardLP]: string[] = yield all([
      call(getBalance, address, token, false),
      call(getReward, token, false),
      call(getStakingRewardLP, token, false),
    ]);

    const dailyReward = +reward / 30;

    // eslint-disable-next-line no-mixed-operators
    const weekIncome = dailyReward * 7 * (+userLP / +stakingRewardLP);
    const weekIncomeWei = Number.isNaN(weekIncome)
      ? '0'
      : yield fromWeiUtil(weekIncome.toFixed(), 'peak');

    yield put(getWeekIncomeSuccess(token, weekIncomeWei));
  } catch (error) {
    toast.error(error.message);
  }
}

export function* getWeekIncomeSaga() {
  yield takeEvery(
    GET_WEEK_INCOME,
    getWeekIncome,
  );
}
