import { RequestStatus } from '@constants';
import {
  GET_APPROVE, SUBMIT_STAKE, SUBMIT_WITHDRAW, SUBMIT_WITHDRAW_REWARD,
} from '@store/contractsInfo/actionTypes';
import { CONNECT_WALLET } from '@store/wallet/actionTypes';
import { UIActions, UIState } from '@types';

const initialState: UIState = {
  [CONNECT_WALLET]: RequestStatus.INIT,
  [GET_APPROVE]: RequestStatus.INIT,
  [SUBMIT_WITHDRAW]: RequestStatus.INIT,
  [SUBMIT_STAKE]: RequestStatus.INIT,
  [SUBMIT_WITHDRAW_REWARD]: RequestStatus.INIT,
};

export const uiReducer = (state: UIState = initialState, action: UIActions): UIState => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|ERROR|RESET)/.exec(type);
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  if (!(requestName in state)) {
    return state;
  }

  return {
    ...state,
    [requestName]: requestState === RequestStatus.RESET
      ? RequestStatus.INIT
      : requestState,
  };
};
