import { btcPoolAbi, ethPoolAbi } from '@assets/abi';
import { fromWeiUtil, getContract } from '@utils';
import { Tokens } from '@types';

export const getApproveCount = async (address: string, token: Tokens) => {
  try {
    const abi = token === 'eth' ? ethPoolAbi : btcPoolAbi;
    const urlUniswap = token === 'eth' ? process.env.REACT_APP_ETH_PEAK_UNISWAP_URL : process.env.REACT_APP_BTC_PEAK_UNISWAP_URL;
    const urlPeakdefi = token === 'eth' ? process.env.REACT_APP_ETH_PEAK_PEAKDEFI_URL : process.env.REACT_APP_BTC_PEAK_PEAKDEFI_URL;

    const contract = await getContract(abi, urlUniswap);

    const allowance = await contract
      .methods
      .allowance(address, urlPeakdefi)
      .call();

    return allowance ? await fromWeiUtil(allowance, 'lp') : '0';
  } catch (error) {
    throw new Error(error.message);
  }
};
