import React from 'react';
import cx from 'classnames';
import { ButtonConnect } from '@components';

import Modal from '../Modal';
import styles from './styles.module.scss';

type SelectWalletModalProps = {
  className?: string,
  isError?: boolean,
  isOpen: boolean,
  onClose: () => void,
  metamaskIsError: boolean,
  walletConnectIsError: boolean,
  metamaskIsLoading: boolean,
  walletConnectIsLoading: boolean,
};

const SelectWalletModal = ({
  className,
  isOpen,
  isError,
  onClose,
  metamaskIsLoading,
  walletConnectIsLoading,
  metamaskIsError,
  walletConnectIsError,
}: SelectWalletModalProps) => (
  <Modal
    title="Select a wallet"
    className={cx(styles.SelectWalletModal, className)}
    isOpen={isOpen}
    onClose={onClose}
    isError={isError}
    classNameContent={styles.content}
  >
    <ButtonConnect walletType="metamask" isLoading={metamaskIsLoading} isError={metamaskIsError} />
    <ButtonConnect walletType="walletConnect" isLoading={walletConnectIsLoading} isError={walletConnectIsError} />
  </Modal>
);

SelectWalletModal.defaultProps = {
  className: '',
  isError: false,
};

export default SelectWalletModal;
