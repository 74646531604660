import { toast } from 'react-toastify';
import { request, reset, success } from '@store/ui/actions';
import { CONNECT_WALLET } from '@store/wallet/actionTypes';
import { getWalletconnectProvider } from '@utils';
import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { ConnectWalletSuccessPayload, Web3Event } from '@types';
import { WalletType } from '@constants';
import { contractsReset } from '@store/contractsInfo/actions';
import { store } from '../index';

export enum WalletconnectRequestMethod {
  eth_accounts = 'eth_accounts',
}

const connect = (address: string) => {
  store.dispatch(success<ConnectWalletSuccessPayload>(
    CONNECT_WALLET,
    {
      address,
      walletType: WalletType.WALLETCONNECT,
    },
  ));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let providerInstance: any = null;

const useWalletconnect = async (): Promise<WalletConnectProvider | null> => {
  try {
    const wc = localStorage.getItem('walletconnect');

    if (!wc || !providerInstance) {
      // @ts-ignore
      providerInstance = new Web3(getWalletconnectProvider());
    }

    const provider: WalletConnectProvider = providerInstance?.currentProvider;
    const storeAddress = store.getState().wallet.address;

    if (!storeAddress && !provider?.connected) {
      await provider.enable();

      connect(provider.accounts[0]);

      provider.on(Web3Event.accountsChanged, (payload: string[]) => {
        connect(payload[0]);
      });

      provider.on(Web3Event.disconnect, () => {
        store.dispatch(contractsReset());
        store.dispatch(reset(CONNECT_WALLET));
        toast.error('WalletConnect was disconnected');
      });

      store.dispatch(request(CONNECT_WALLET, { walletType: WalletType.WALLETCONNECT }));

      toast.success('WalletConnect was connected');
    }

    return providerInstance;
  } catch {
    store.dispatch(reset(CONNECT_WALLET));
    toast.error('WalletConnect connection error');
    return null;
  }
};

export default useWalletconnect;
