import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import cx from 'classnames';
import { timestampToTime } from '@utils';
import { useTimer } from '@hooks';

import styles from './styles.module.scss';

type TimerProps = {
  className?: string,
  time: string,
};

const Timer = ({
  className,
  time,
}: TimerProps) => {
  const timeTimestamp = useRef(time);

  const getDifferenceTimestamp = useCallback(() => {
    const dateNow = new Date().getTime();
    return Math.ceil((Number(timeTimestamp.current) - dateNow) / 1000);
  }, [timeTimestamp]);

  const { seconds, setSeconds } = useTimer(getDifferenceTimestamp());

  const getResidualTime = useCallback(() => {
    const differenceTimestamp = getDifferenceTimestamp();

    if (differenceTimestamp < 0) {
      return timestampToTime(0);
    }

    return timestampToTime(differenceTimestamp);
  }, [time]);

  const [residualTime, setResidualTime] = useState(getResidualTime());

  useEffect(() => {
    if (seconds >= 0) {
      setResidualTime(getResidualTime());
    }
  }, [seconds]);

  useEffect(() => {
    timeTimestamp.current = time;
    setSeconds(getDifferenceTimestamp());
  }, [time]);

  const renderBlock = useCallback((value: number, description: string, styleClass?: string) => (
    <div className={cx(styles.block, styleClass)}>
      <span className={styles.blockValue}>{value}</span>
      <span className={styles.blockDescription}>{description}</span>
    </div>
  ), []);

  return (
    <div className={cx(styles.timer, className)}>
      {renderBlock(residualTime.days, 'days', styles.firstLineMobile)}
      <span className={cx(styles.dots, styles.firstLineMobile)}>:</span>
      {renderBlock(residualTime.hours, 'hours', styles.firstLineMobile)}
      <span className={cx(styles.dots, styles.dotsNoPhone)}>:</span>
      {renderBlock(residualTime.minutes, 'min')}
      <span className={styles.dots}>:</span>
      {renderBlock(residualTime.seconds, 'sec')}
    </div>
  );
};

Timer.defaultProps = {
  className: '',
};

export default Timer;
