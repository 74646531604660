import React from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type PeakAvailableProps = {
  className?: string,
  token: string,
  amount: string,
  colorAmount?: 'green' | 'white' | 'greyLight',
  weightAmount?: 'bold' | 'regular',
  size?: 'micro' | 'small' | 'large',
  postfix?: 'available' | 'approved',
  colorPostfix?: 'grey' | 'green' | 'greyLight',
};

const PeakAvailable = ({
  className,
  amount,
  token,
  size = 'large',
  colorAmount = 'green',
  weightAmount = 'bold',
  postfix = 'available',
  colorPostfix = 'grey',
}: PeakAvailableProps) => (
  <div className={cx(styles.peakAvailable, styles[size], className)}>
    <div className={styles.tokenWrapper}>
      <span className={cx(styles.peak, styles[colorAmount], styles[weightAmount])}>{amount}</span>
      <span className={cx(styles.token, styles[colorAmount], styles[weightAmount])}>{token}</span>
    </div>
    <span className={cx(styles.available, styles[colorPostfix])}>{`${postfix}`}</span>
  </div>
);

PeakAvailable.defaultProps = {
  className: '',
  size: 'large',
  colorAmount: 'green',
  postfix: 'available',
  colorPostfix: 'grey',
  weightAmount: 'bold',
};

export default PeakAvailable;
