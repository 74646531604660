import React, { ReactNode } from 'react';
import { FAQ } from '@types';
import styles from './styles.module.scss';

export const FAQS: FAQ[] = [{
  title: 'How to participate in the Liquidity mining program?',
  content: 'You need to provide liquidity on Uniswap for the PEAK/ETH or PEAK/WBTC pool. Once you have done this, you will receive a LP token. You can stake this LP token here and get the PEAK rewards for it.',
}, {
  title: 'How long does the Liquidity Mining Program run and how many rewards will be distributed in total?',
  content: 'The Liquidity mining program runs for 30 days. A total of 88,000,000 PEAK will be distributed.',
}, {
  title: 'What has been done to minimize risks to provide security?\n',
  content: 'We had a smart contract security audit done by hacken.io to ensure the best possible smart contract security. Despite all precautions you can never exclude residual risks in the DeFi space. Please do your own research as you bear full responsibility for your decisions.',
}, {
  title: 'How can I connect to the website or Smart Contract?',
  content: 'You need a web3 wallet for Ethereum such as Metamask or Wallet Connect.',
}];

export const STEPS: ReactNode[] = [
  <p className={styles.step}>
    Provide liquidity to
    {' '}
    <span className={styles.stepGreen}>pools</span>
    {' '}
    on Uniswap and get LP tokens
  </p>,
  <p className={styles.step}>Stake LP tokens and participate in PEAK farming</p>,
  <p className={styles.step}>Earn PEAK tokens</p>,
];
