import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { State, SubmitWithdrawValue } from '@types';
import { toast } from 'react-toastify';
import { submitWithdraw } from '@api/deposits/submitWithdraw';
import { SUBMIT_WITHDRAW } from '@store/contractsInfo/actionTypes';
import {
  getBalance, getContractApy, getContractsInfo, getWeekIncome,
} from '@store/contractsInfo/actions';
import { error, request, success } from '@store/ui/actions';

function* withdraw({ payload, type }: SubmitWithdrawValue) {
  try {
    yield put(request(type));
    const { token } = payload;
    const address = yield select((state: State) => state.wallet.address);

    yield call(submitWithdraw, address, token);
    yield put(getContractsInfo(token));
    yield put(getBalance());
    yield put(getContractApy(token));
    yield put(getWeekIncome(token));
    yield put(success(type));
  } catch (e) {
    toast.error(e.message);
    yield put(error(type));
  }
}

export function* submitWithdrawSaga() {
  yield takeLatest(
    SUBMIT_WITHDRAW,
    withdraw,
  );
}
