import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { GET_CONTRACT_APY } from '@store/contractsInfo/actionTypes';
import { GetContractApy } from '@types';
import { toast } from 'react-toastify';
import { getTotalSupply } from '@api/deposits/getTotalSupply';
import { getReward } from '@api/deposits/getReward';
import { getAllPeaks } from '@api/deposits/getAllPeaks';
import { getContractApySuccess } from '@store/contractsInfo/actions';
import { getStakingRewardLP } from '@api/deposits/getStakingRewardLP';

/*

pair = ...UNISWAP_POOL

stakingReward = ...PEAKDEFI_POOL

peak = ..PEAK_TOKEN

stakingRewardLP = pair.balanceOf(stakingReward.address)

totalLP = pair.totalSupply()

totalPEAK = peak.balanceOf(pair.address)

lpPriceInPeak = 2 * totalPEAK / totalLP

dailyReward = reward / 30

userReward = dailyReward * 365 / stakingRewardLP

APY = userReward / userLiquidity * 100

*/

function* getContract({ payload }: GetContractApy) {
  try {
    const token = payload;

    const [
      totalLp, totalPeak, stakingRewardLP, reward,
    ]: string[] = yield all([
      call(getTotalSupply, token, false),
      call(getAllPeaks, token, false),
      call(getStakingRewardLP, token, false),
      call(getReward, token, false),
    ]);

    // eslint-disable-next-line no-mixed-operators
    const lpPriceInPeak = 2 * +totalPeak / +totalLp;
    const dailyReward = +reward / 30;

    // eslint-disable-next-line no-mixed-operators
    const userReward = dailyReward * 365 / +stakingRewardLP;

    // eslint-disable-next-line no-mixed-operators
    const apy = (userReward / lpPriceInPeak * 100).toFixed(2);
    const resultApy = Number.isNaN(apy) ? '0' : apy;

    yield put(getContractApySuccess(token, resultApy));
  } catch (error) {
    toast.error(error.message);
  }
}

export function* getContractApySaga() {
  yield takeEvery(
    GET_CONTRACT_APY,
    getContract,
  );
}
