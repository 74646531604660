import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Pools, Manage } from '@pages';
import { routes } from '@constants';

const Routes = () => (
  <Switch>
    <Route
      path={routes.manage}
      exact
      component={Manage}
    />
    <Route
      path={routes.pools}
      component={Pools}
    />
  </Switch>
);

export default Routes;
