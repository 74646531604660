import React, { ChangeEvent, forwardRef, KeyboardEvent } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type InputProps = {
  className?: string,
  id: string,
  label: string,
  value: string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  onMaxClick: () => void,
};

const Input = forwardRef<HTMLInputElement, InputProps>(({
  className,
  id,
  label,
  value,
  onChange,
  onMaxClick,
}, ref) => {
  const handlerKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'e' || e.key === '-') {
      e.preventDefault();
    }
  };

  return (
    <div
      className={cx(styles.inputWrapper, className)}
    >
      <label
        htmlFor={id}
        className={styles.inputInner}
      >
        <input
          type="number"
          id={id}
          ref={ref}
          className={styles.input}
          placeholder="0"
          value={value}
          onKeyPress={handlerKeyPress}
          min={0}
          onChange={onChange}
        />
        <span className={styles.label}>{label}</span>
      </label>
      <button type="button" className={styles.buttonMax} onClick={onMaxClick}>Max</button>
    </div>
  );
});

Input.defaultProps = {
  className: '',
};

export default Input;
