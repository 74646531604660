import React from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type BalanceBlockProps = {
  token?: 'peak' | 'lp',
  amount: string,
  className?: string,
  withShadow?: boolean,
};

const BalanceBlock = ({
  className,
  token,
  amount,
  withShadow,
}: BalanceBlockProps) => (
  <section className={cx(
    { [styles.shadow]: withShadow },
    styles.balanceBlock,
    className,
  )}
  >
    <div className={styles.background} />
    <div className={styles.content}>
      <div className={styles.header}>
        <h2 className={styles.token}>{`${token === 'lp' ? 'Deposited LP tokens' : 'Reward PEAK'}`}</h2>
      </div>
      <div className={cx(
        styles.block,
        token === 'peak' && styles.blockPeak,
      )}
      >
        <span className={cx(
          styles.amount,
          token === 'peak' && styles.amountMaxWidth,
        )}
        >
          {amount}
        </span>
      </div>
    </div>
  </section>
);

BalanceBlock.defaultProps = {
  className: '',
  token: 'lp',
  withShadow: false,
};

export default BalanceBlock;
