// @flow

import { WalletState, WalletActions } from '@types';
import { WalletType } from '@constants';
import actionTypes from './actionTypes';

const initialState: WalletState = {
  walletType: WalletType.UNSELECTED,
  isConnected: false,
  address: '',
};

export const walletReducer = (
  state: WalletState = initialState,
  action: WalletActions,
): WalletState => {
  switch (action.type) {
    case actionTypes.CONNECT_WALLET_REQUEST: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }

    case actionTypes.CONNECT_WALLET_SUCCESS: {
      const { payload } = action;
      return {
        ...initialState,
        ...payload,
        isConnected: true,
      };
    }

    case actionTypes.CONNECT_WALLET_ERROR: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }

    case actionTypes.CONNECT_WALLET_RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
