import { btcPoolAbi, ethPoolAbi } from '@assets/abi';
import { getContract } from '@utils';
import { Tokens } from '@types';

export const getApprove = async (address: string, token: Tokens) => {
  try {
    const abi = token === 'eth' ? ethPoolAbi : btcPoolAbi;
    const url = token === 'eth' ? process.env.REACT_APP_ETH_PEAK_UNISWAP_URL : process.env.REACT_APP_BTC_PEAK_UNISWAP_URL;
    const urlSend = token === 'eth' ? process.env.REACT_APP_ETH_PEAK_PEAKDEFI_URL : process.env.REACT_APP_BTC_PEAK_PEAKDEFI_URL;

    const contract = await getContract(abi, url);
    const maxUint = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

    return contract
      .methods
      .approve(urlSend, maxUint)
      .send({
        from: address,
      });
  } catch (error) {
    throw new Error(error.message);
  }
};
