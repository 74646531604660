import React, { ReactNode } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type DropdownProps = {
  className?: string,
  title: string,
  content: ReactNode,
};

const Dropdown = ({
  className,
  title,
  content,
}: DropdownProps) => (
  <details className={cx(styles.dropdown, className)}>
    <summary className={styles.summary}>{title}</summary>
    <p className={styles.body}>
      {content}
    </p>
  </details>
);

Dropdown.defaultProps = {
  className: '',
};

export default Dropdown;
