export const CONNECT_WALLET: 'CONNECT_WALLET' = 'CONNECT_WALLET';
export const CONNECT_WALLET_REQUEST: 'CONNECT_WALLET_REQUEST' = 'CONNECT_WALLET_REQUEST';
export const CONNECT_WALLET_SUCCESS: 'CONNECT_WALLET_SUCCESS' = 'CONNECT_WALLET_SUCCESS';
export const CONNECT_WALLET_ERROR: 'CONNECT_WALLET_ERROR' = 'CONNECT_WALLET_ERROR';
export const CONNECT_WALLET_RESET: 'CONNECT_WALLET_RESET' = 'CONNECT_WALLET_RESET';

export default {
  CONNECT_WALLET,
  CONNECT_WALLET_REQUEST,
  CONNECT_WALLET_SUCCESS,
  CONNECT_WALLET_ERROR,
  CONNECT_WALLET_RESET,
};
