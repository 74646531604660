import { WalletType } from '@constants';
import detectEthereumProvider from '@metamask/detect-provider';
import {
  error, request, reset, success,
} from '@store/ui/actions';
import { CONNECT_WALLET } from '@store/wallet/actionTypes';
import { ConnectWalletErrorPayload, ConnectWalletSuccessPayload, Web3Event } from '@types';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import { contractsReset } from '@store/contractsInfo/actions';
import { store } from '../index';

export enum MetamaskRequestMethod {
  eth_requestAccounts = 'eth_requestAccounts',
  eth_accounts = 'eth_accounts',
}

const connect = (address: string) => {
  store.dispatch(success<ConnectWalletSuccessPayload>(
    CONNECT_WALLET,
    {
      address,
      walletType: WalletType.METAMASK,
    },
  ));
};

export type Provider = typeof Web3.givenProvider;

let providerInstance: Provider;

const useMetamask = async (): Promise<Provider | null> => {
  if (!providerInstance) {
    const provider = await detectEthereumProvider();
    // @ts-ignore
    providerInstance = new Web3(provider);
  }
  const provider: Provider = providerInstance.currentProvider;

  const storeAddress = store.getState().wallet.address;

  if (!provider || !provider.isMetaMask) {
    store.dispatch(error<ConnectWalletErrorPayload>(CONNECT_WALLET, { error: 'metamask not available' }));
    toast.error('Please install the MetaMask extension');
    return null;
  }

  if (!storeAddress) {
    store.dispatch(request(CONNECT_WALLET, { walletType: WalletType.METAMASK }));

    try {
      const res = await provider.request({ method: MetamaskRequestMethod.eth_requestAccounts });
      const address = res[0];
      connect(address);

      provider.on(Web3Event.disconnect, () => {
        store.dispatch(reset(CONNECT_WALLET));
        toast.error('MetaMask was disconnected');
      });

      provider.on(Web3Event.accountsChanged, (payload: string[]) => {
        connect(payload[0]);
      });

      toast.success('MetaMask was connected');
    } catch {
      store.dispatch(contractsReset());
      store.dispatch(reset(CONNECT_WALLET));
      toast.error('MetaMask connection error');
      return null;
    }
  }

  return providerInstance;
};

export default useMetamask;
