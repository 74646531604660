import React from 'react';
import cx from 'classnames';
import { IconBtc, IconEth } from '@assets/icons';
import { ButtonTheme, CardStates } from '@types';
import Button from '../Button';

import styles from './styles.module.scss';

type Types = 'PEAK/ETH' | 'PEAK/WBTC';

type Type = {
  icon: string,
  title: string,
  pool: string,
  className: string,
  link: string,
};

const types: Record<Types, Type> = {
  'PEAK/ETH': {
    icon: IconEth,
    title: 'PEAK/ETH',
    pool: 'PEAK/ETH',
    className: 'eth',
    link: 'https://app.uniswap.org/#/add/ETH/0x630d98424eFe0Ea27fB1b3Ab7741907DFFEaAd78',
  },
  'PEAK/WBTC': {
    icon: IconBtc,
    title: 'PEAK/WBTC',
    pool: 'Peak/WBTC',
    className: 'btc',
    link: 'https://app.uniswap.org/#/add/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/0x630d98424eFe0Ea27fB1b3Ab7741907DFFEaAd78',
  },
};

type State = {
  value: string,
  buttonTheme: ButtonTheme,
  callback: () => void,
};

type CardProps = {
  className?: string,
  type: Types,
  state: CardStates,
  openSelectWallet: () => void,
  onClick: () => void,
};

const Card = ({
  className,
  type,
  state,
  openSelectWallet,
  onClick,
}: CardProps) => {
  const states: Record<CardStates, State> = {
    deposit: {
      value: 'Manage',
      buttonTheme: 'green',
      callback: onClick,
    },
    manage: {
      value: 'Manage',
      buttonTheme: 'green',
      callback: onClick,
    },
    wallet: {
      value: 'Unlock wallet',
      buttonTheme: 'blue',
      callback: openSelectWallet,
    },
  };

  return (
    <section className={cx(
      styles.card,
      styles[state],
      styles[types[type].className],
      className,
    )}
    >
      <div className={styles.inner}>
        <div className={styles.topRow}>
          <img src={types[type].icon} alt={types[type].title} />
          <h4 className={styles.title}>{types[type].title}</h4>
          <Button
            className={styles.button}
            value={states[state].value}
            onClick={states[state].callback}
            theme={states[state].buttonTheme}
          />
        </div>
        <a href={types[type].link} target="_blank" rel="noopener noreferrer" className={styles.link}>
          {`Pool ${types[type].pool}`}
        </a>
        {/* <div className={styles.bottomRow}>
          <span className={styles.apy}>APY</span>
          <span className={styles.percentage}>{`${percentage}%`}</span>
        </div> */}
      </div>
    </section>
  );
};

Card.defaultProps = {
  className: '',
};

export default Card;
