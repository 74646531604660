import React, { useMemo } from 'react';
import cx from 'classnames';
import { IconLogo } from '@assets/icons';
import { Link } from 'react-router-dom';
import { HeaderBalance } from '@types';
import Button from '../Button';
import BalancerInfo from '../BalanceInfo';
import styles from './styles.module.scss';

type HeaderProps = {
  className?: string,
  isEnabled: boolean,
  balances: HeaderBalance[],
  address: string,
  onAddressClick: () => void,
  onUnlockClick: () => void,
};

const Header = ({
  className, isEnabled, balances, address, onAddressClick, onUnlockClick,
}: HeaderProps) => {
  const renderBalances = useMemo(() => balances.map((item) => (
    <BalancerInfo
      key={item.currency}
      value={item.value}
      currency={item.currency}
      className={styles.currencyInfo}
    />
  )), [balances]);

  const addressSplice = useMemo(() => {
    if (address) {
      return {
        partFirst: address.substr(0, address.length - 4),
        partSecond: address.substr(-4),
      };
    }

    return {
      partFirst: '',
      partSecond: '',
    };
  }, [address]);

  const buttonValue = useMemo(() => (
    <>
      <span className={styles.partOne}>{addressSplice.partFirst}</span>
      <span>{addressSplice.partSecond}</span>
    </>
  ), [addressSplice]);

  return (
    <header className={cx(styles.header, className)}>
      <div className={cx('container', styles.inner)}>
        <Link to="/" className={styles.link}>
          <img src={IconLogo} alt="logo" />
        </Link>
        {isEnabled ? (
          <div className={styles.info}>
            {renderBalances}
            <Button
              className={styles.buttonAddress}
              theme="green"
              value={buttonValue}
              onClick={onAddressClick}
            />
          </div>
        ) : (
          <Button theme="blue" value="Unlock wallet" onClick={onUnlockClick} />
        )}
      </div>
    </header>
  );
};

Header.defaultProps = {
  className: '',
};

export default Header;
