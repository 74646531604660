import { btcPeakAbi, ethPeakAbi } from '@assets/abi';
import { getContract, fromEthToWei } from '@utils';
import { Tokens } from '@types';

export const submitStake = async (address: string, value: string, token: Tokens) => {
  try {
    const abi = token === 'eth' ? ethPeakAbi : btcPeakAbi;
    const url = token === 'eth' ? process.env.REACT_APP_ETH_PEAK_PEAKDEFI_URL : process.env.REACT_APP_BTC_PEAK_PEAKDEFI_URL;

    const contract = await getContract(abi, url);

    const amountToWei = await fromEthToWei(value);

    return contract
      .methods
      .stake(amountToWei)
      .send({
        from: address,
      });
  } catch (error) {
    throw new Error(error.message);
  }
};
