import { connectRouter } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import { history } from '@utils';
import { persistReducer } from 'redux-persist';
import { contractsInfoReducer } from '@store/contractsInfo';
import { uiReducer } from './ui';
import { walletReducer } from './wallet';

const walletPersistConfig = {
  key: 'wallet',
  storage,
  whitelist: ['walletType'],
};

export default {
  router: connectRouter(history),
  ui: uiReducer,
  wallet: persistReducer(walletPersistConfig, walletReducer),
  contractsInfo: contractsInfoReducer,
};
