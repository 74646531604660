export default [{
  inputs: [{ internalType: 'address', name: '_logic', type: 'address' }, {
    internalType: 'address',
    name: '_admin',
    type: 'address',
  }, { internalType: 'bytes', name: '_data', type: 'bytes' }],
  stateMutability: 'payable',
  type: 'constructor',
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'previousAdmin',
    type: 'address',
  }, {
    indexed: false, internalType: 'address', name: 'newAdmin', type: 'address',
  }],
  name: 'AdminChanged',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'owner', type: 'address',
  }, {
    indexed: true,
    internalType: 'address',
    name: 'spender',
    type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
  }],
  name: 'Approval',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
  }, {
    indexed: true,
    internalType: 'bytes32',
    name: 'previousAdminRole',
    type: 'bytes32',
  }, {
    indexed: true, internalType: 'bytes32', name: 'newAdminRole', type: 'bytes32',
  }],
  name: 'RoleAdminChanged',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
  }, {
    indexed: true,
    internalType: 'address',
    name: 'account',
    type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }],
  name: 'RoleGranted',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
  }, {
    indexed: true,
    internalType: 'address',
    name: 'account',
    type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }],
  name: 'RoleRevoked',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'from', type: 'address',
  }, {
    indexed: true,
    internalType: 'address',
    name: 'to',
    type: 'address',
  }, {
    indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
  }],
  name: 'Transfer',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'implementation', type: 'address',
  }],
  name: 'Upgraded',
  type: 'event',
}, { stateMutability: 'payable', type: 'fallback' }, {
  inputs: [],
  name: 'DEFAULT_ADMIN_ROLE',
  outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'MINTER_ROLE',
  outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'admin',
  outputs: [{ internalType: 'address', name: 'admin_', type: 'address' }],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'owner', type: 'address' }, {
    internalType: 'address',
    name: 'spender',
    type: 'address',
  }],
  name: 'allowance',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, {
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256',
  }],
  name: 'approve',
  outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
  name: 'balanceOf',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'newAdmin', type: 'address' }],
  name: 'changeAdmin',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [],
  name: 'decimals',
  outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, {
    internalType: 'uint256',
    name: 'subtractedValue',
    type: 'uint256',
  }],
  name: 'decreaseAllowance',
  outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
  name: 'getRoleAdmin',
  outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, {
    internalType: 'uint256',
    name: 'index',
    type: 'uint256',
  }],
  name: 'getRoleMember',
  outputs: [{ internalType: 'address', name: '', type: 'address' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
  name: 'getRoleMemberCount',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, {
    internalType: 'address',
    name: 'account',
    type: 'address',
  }],
  name: 'grantRole',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, {
    internalType: 'address',
    name: 'account',
    type: 'address',
  }],
  name: 'hasRole',
  outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'implementation',
  outputs: [{ internalType: 'address', name: 'implementation_', type: 'address' }],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, {
    internalType: 'uint256',
    name: 'addedValue',
    type: 'uint256',
  }],
  name: 'increaseAllowance',
  outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [],
  name: 'name',
  outputs: [{ internalType: 'string', name: '', type: 'string' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, {
    internalType: 'address',
    name: 'account',
    type: 'address',
  }],
  name: 'renounceRole',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, {
    internalType: 'address',
    name: 'account',
    type: 'address',
  }],
  name: 'revokeRole',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [],
  name: 'symbol',
  outputs: [{ internalType: 'string', name: '', type: 'string' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [],
  name: 'totalSupply',
  outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
  stateMutability: 'view',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'recipient', type: 'address' }, {
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256',
  }],
  name: 'transfer',
  outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'sender', type: 'address' }, {
    internalType: 'address',
    name: 'recipient',
    type: 'address',
  }, { internalType: 'uint256', name: 'amount', type: 'uint256' }],
  name: 'transferFrom',
  outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'newImplementation', type: 'address' }],
  name: 'upgradeTo',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}, {
  inputs: [{
    internalType: 'address',
    name: 'newImplementation',
    type: 'address',
  }, { internalType: 'bytes', name: 'data', type: 'bytes' }],
  name: 'upgradeToAndCall',
  outputs: [],
  stateMutability: 'payable',
  type: 'function',
}, { stateMutability: 'payable', type: 'receive' }];
