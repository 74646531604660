import {
  ContractInfo, GetApproveValuePayload, GetApproveValueSuccessPayload, Tokens,
} from '@types';
import {
  GET_APPROVE, GET_APPROVE_SUCCESS, GET_BALANCE, GET_BALANCE_SUCCESS, GET_CONTRACTS_INFO,
  GET_CONTRACTS_INFO_SUCCESS, SUBMIT_STAKE, SUBMIT_WITHDRAW, GET_TIME, GET_TIME_SUCCESS,
  SUBMIT_WITHDRAW_REWARD, CONTRACTS_RESET, GET_CONTRACT_APY, GET_CONTRACT_APY_SUCCESS,
  GET_AVAILABLE, GET_AVAILABLE_SUCCESS, GET_WEEK_INCOME, GET_WEEK_INCOME_SUCCESS,
} from '@store/contractsInfo/actionTypes';

export const getContractsInfo = (token: Tokens) => ({
  type: GET_CONTRACTS_INFO,
  token,
});

export const getContractsInfoSuccess = (
  token: Tokens,
  payload: Partial<ContractInfo>,
) => ({
  type: GET_CONTRACTS_INFO_SUCCESS,
  payload: {
    token,
    payload,
  },
});

export const getApproveValue = (payload: GetApproveValuePayload) => ({
  type: GET_APPROVE,
  payload,
});

export const getApproveValueSuccess = (payload: GetApproveValueSuccessPayload) => ({
  type: GET_APPROVE_SUCCESS,
  payload,
});

export const submitStakeValue = (token: Tokens, value: string) => ({
  type: SUBMIT_STAKE,
  payload: {
    token,
    value,
  },
});

export const submitWithdrawValue = (token: Tokens, value: string) => ({
  type: SUBMIT_WITHDRAW,
  payload: {
    token,
    value,
  },
});

export const getBalance = () => ({
  type: GET_BALANCE,
});

export const getBalanceSuccess = (eth: string, peak: string) => ({
  type: GET_BALANCE_SUCCESS,
  payload: {
    eth,
    peak,
  },
});

export const getTime = () => ({
  type: GET_TIME,
});

export const getTimeSuccess = (time: string) => ({
  type: GET_TIME_SUCCESS,
  payload: time,
});

export const submitWithdrawRewardValue = (token: Tokens) => ({
  type: SUBMIT_WITHDRAW_REWARD,
  payload: token,
});

export const contractsReset = () => ({
  type: CONTRACTS_RESET,
});

export const getContractApy = (token: Tokens) => ({
  type: GET_CONTRACT_APY,
  payload: token,
});

export const getContractApySuccess = (token: Tokens, apy: string) => ({
  type: GET_CONTRACT_APY_SUCCESS,
  payload: {
    token,
    apy,
  },
});

export const getAvailableValue = (token: Tokens) => ({
  type: GET_AVAILABLE,
  payload: token,
});

export const getAvailableValueSuccess = (token: Tokens, available: string) => ({
  type: GET_AVAILABLE_SUCCESS,
  payload: {
    token,
    available,
  },
});

export const getWeekIncome = (token: Tokens) => ({
  type: GET_WEEK_INCOME,
  payload: token,
});

export const getWeekIncomeSuccess = (token: Tokens, weekIncome: string) => ({
  type: GET_WEEK_INCOME_SUCCESS,
  payload: {
    token,
    weekIncome,
  },
});
