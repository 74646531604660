import React, { useCallback } from 'react';
import cx from 'classnames';
import { IconMetamask, IconWallet } from '@assets/icons';
import { useMetamask, useWalletconnect } from '@hooks';
import { WalletsType } from '@types';
import styles from './styles.module.scss';

type WalletType = {
  icon: string,
  title: string,
  callback: () => void,
};

const wallets: Record<WalletsType, WalletType> = {
  metamask: {
    icon: IconMetamask,
    title: 'Metamask',
    callback: useMetamask,
  },
  walletConnect: {
    icon: IconWallet,
    title: 'Wallet connect',
    callback: useWalletconnect,
  },
};

type ButtonConnectProps = {
  className?: string,
  walletType: WalletsType,
  isError: boolean,
  isLoading: boolean,
};

const ButtonConnect = ({
  className,
  walletType,
  isError,
  isLoading,
}: ButtonConnectProps) => {
  const onClick = useCallback(() => {
    wallets[walletType].callback();
  }, [walletType]);

  return (
    <button
      type="button"
      className={cx(
        styles.buttonConnect,
        { [styles.error]: isError },
        className,
      )}
      disabled={isLoading}
      onClick={onClick}
    >
      { isError ? 'Try again' : (
        <>
          <img src={wallets[walletType].icon} alt={wallets[walletType].title} />
          <p className={styles.value}>{wallets[walletType].title}</p>
        </>
      )}
    </button>
  );
};

ButtonConnect.defaultProps = {
  className: '',
};

export default ButtonConnect;
