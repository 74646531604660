import { fromWeiUtil, web3 } from '@utils';

export const getBalanceEth = async (address: string, isFromWei: boolean = true) => {
  try {
    const provider = await web3();
    const balance = await provider.eth.getBalance(address);

    if (isFromWei) {
      return await fromWeiUtil(balance, 'lp');
    }

    return balance;
  } catch (error) {
    throw new Error('Error getting balance ETH');
  }
};
