type TimestampToTime = {
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
};

const SEC_IN_MINUTE = 60;
const SEC_IN_HOUR = SEC_IN_MINUTE * 60;
const SEC_IN_DAY = SEC_IN_HOUR * 24;

const timestampToTime = (timestamp: number): TimestampToTime => {
  const days = Math.trunc(timestamp / SEC_IN_DAY);
  const timestampWithoutDays = timestamp - days * SEC_IN_DAY;

  const hours = Math.trunc(timestampWithoutDays / SEC_IN_HOUR);
  const timestampWithoutHours = timestampWithoutDays - hours * SEC_IN_HOUR;

  const minutes = Math.trunc(timestampWithoutHours / SEC_IN_MINUTE);
  const seconds = Math.ceil(timestampWithoutHours - minutes * SEC_IN_MINUTE);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

export default timestampToTime;
