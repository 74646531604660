import { fromWeiUtil, getContract } from '@utils';
import { tokenProxiAbi } from '@assets/abi';

export const getBalancePeak = async (address: string, isFromWei: boolean = true) => {
  try {
    const contract = await getContract(tokenProxiAbi, process.env.REACT_APP_TOKEN_PROXI_URL);

    const available = await contract
      .methods
      .balanceOf(address)
      .call();

    if (isFromWei) {
      return await fromWeiUtil(available, 'peak');
    }

    return available;
  } catch (error) {
    throw new Error('Error getting available');
  }
};
