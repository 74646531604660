import React, { useCallback, useContext, useEffect } from 'react';
import {
  Card, Faq, Mask, Steps, Timer,
} from '@components';
import cx from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CardStates, State, Tokens } from '@types';
import { Dispatch as DispatchRedux } from 'redux';
import { getContractApy, getTime } from '@store/contractsInfo/actions';
import { FAQS, STEPS } from './contstants';
import styles from './styles.module.scss';
import { ContextPopups } from '../../containers/Layout';

const mapState = (state: State) => ({
  isConnected: state.wallet.isConnected,
  time: state.contractsInfo.time,
  contracts: state.contractsInfo,
  address: state.wallet.address,
  walletType: state.wallet.walletType,
});

const mapDispatch = (dispatch: DispatchRedux) => ({
  onGetTime: () => dispatch(getTime()),
  onLoadContractApy: (token: Tokens) => dispatch(getContractApy(token)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Pools = ({
  isConnected, time, contracts, onGetTime, onLoadContractApy, address, walletType,
}: PropsFromRedux) => {
  const { setIsOpenSelectWallet } = useContext(ContextPopups);
  const history = useHistory();

  useEffect(() => {
    onGetTime();
    if (walletType === 'UNSELECTED') {
      onLoadContractApy('eth');
      onLoadContractApy('wbtc');
    }
  }, []);

  useEffect(() => {
    if (walletType !== 'UNSELECTED' && address) {
      onLoadContractApy('eth');
      onLoadContractApy('wbtc');
    }
  }, [address]);

  const onOpenSelectWalletClick = useCallback(
    () => setIsOpenSelectWallet(true),
    [setIsOpenSelectWallet],
  );

  const onClickCard = useCallback(
    (token: Tokens) => history.push(`/manage/${token}`),
    [history],
  );

  const getStateCard = useCallback((token: Tokens): CardStates => {
    if (isConnected) {
      return contracts[token].lp === '0' ? 'deposit' : 'manage';
    }

    return 'wallet';
  }, [contracts]);

  return (
    <div className={styles.pools}>
      <Mask className={styles.mask}>
        <div className="container">
          <section className={cx(styles.steps)}>
            <h2 className={styles.h2Hidden}>Steps</h2>
            <Steps values={STEPS} />
          </section>
        </div>
        <section className={cx(styles.sectionTimer, 'container')}>
          <h2 className={cx(styles.h2, styles.timerHeader)}>Finished!</h2>
          <Timer className={styles.timer} time={time} />
        </section>
      </Mask>
      <div className="container">
        <section className={styles.sectionCards}>
          <h2 className={cx(styles.h2, styles.cardsTitle)}>Participating pools</h2>
          <div className={styles.cards}>
            <Card
              openSelectWallet={onOpenSelectWalletClick}
              className={styles.card}
              type="PEAK/ETH"
              state={getStateCard('eth')}
              onClick={() => onClickCard('eth')}
            />
            <Card
              openSelectWallet={onOpenSelectWalletClick}
              className={styles.card}
              type="PEAK/WBTC"
              state={getStateCard('wbtc')}
              onClick={() => onClickCard('wbtc')}
            />
          </div>
        </section>

        <Faq className={styles.faq} faqs={FAQS} />
      </div>
    </div>
  );
};

export default connector(Pools);
