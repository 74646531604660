import {
  useEffect, useState, Dispatch, SetStateAction,
} from 'react';

type TimerReturn = {
  seconds: number;
  setSeconds: Dispatch<SetStateAction<number>>;
};

const useTimer = (initialSecond: number): TimerReturn => {
  const [seconds, setSeconds] = useState<number>(initialSecond);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (seconds > 0) {
        setSeconds((state) => state - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  });

  return {
    seconds,
    setSeconds,
  };
};

export default useTimer;
